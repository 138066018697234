//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Screen employees making in-home health visits',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Home health has a new business challenge: keeping your employees safe from COVID and healthy on-the-job',
    descriptionColor: 'white-1',
    image: 'usecaseHomehealth',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'green-1',
    bgcolor: 'blue-1',
    bggradient: 'seatosky',
  },
  {
    title: 'Screen home health employess for COVID symptoms',
    titleWeight: 'medium',
    description:
      'Keep your home health employees and patients safe with automated SMS health checks before in-home visits`',
    image: 'usecaseHomehealthSolution',
    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'sponsorship',
  },
]
const featureData = {
  title: 'Keep your home health employees and clients safe',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Automate home health symptom screenings',
          colbgcolor: 'dkblue-1',
          iconname: 'homehealth',
          description:
            "With employees visiting patients in-home, schedule automated SMS symptom screenings to make sure they're safe",
        },
        {
          title: 'Evaluate worker screening responses',
          colbgcolor: 'sky-1',
          iconname: 'recentactors',
          description:
            'Compare employee health screening responses to criteria you define, and display custom messages based on the result',
        },
        {
          title: 'Simplify with text messaging',
          colbgcolor: 'bluegreen-1',
          iconname: 'touchapp',
          description:
            'Simplify interactions with workers through text messaging - no apps to download or install',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Picohealth - Home Health - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your home health agency employees',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Keep home health employees and patients safe
          </h1>
          <p class="display-4">
            With your employees visiting multiple homes each day, use automated
            SMS symptom screeners to identify health risks that may occur
            &mdash; and keep them and your patients safe.
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="purple-1"
      bottomclass="white-4"
      flip={false}
      lineclass="blue-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="red-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
