//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

export default class Nav extends React.Component {
  render() {
    return (
      <nav class="navbar navbar-sub navbar-expand-lg bg-yellow-6 border-top-1-yellow-5 border-bottom-1-yellow-5">
        <div class="container">
          <div
            class="d-flex flex-row align-items-center justify-content-center"
            id="navbarChallenge"
          >
            {/* <div
              class="card-title-solo black-3 text-right pr-4"
              style={{ width: "160px" }}
            >
              Challenges
            </div> */}
            <ul class="navbar-nav mr-auto">
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/retail/"
                >
                  Retail
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/construction/"
                >
                  Construction
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/home-health/"
                >
                  Home Health
                </Link>
              </li>

              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/restaurants/"
                >
                  Restaurants
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/gyms/"
                >
                  Gyms
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link yellow-1 active"
                  to="/use-cases/travel/"
                >
                  Travel &amp; Hospitality
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
